import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import CategorizedOptions from "../../util/CategorizedMasterData";

const UNSPECIFIED = {
  code: "",
  label: "（未選択）",
};

const StandardSelect = (props) => {
  const {
    blank,
    blankLabel,
    categorize,
    disabled,
    form,
    inputFeedback,
    labelText,
    name,
    onChange: change,
    options: selectOptions,
  } = props;

  const formControlProps = () => ({
    disabled,
    error: helperText() ? true : false,
    margin: "normal",
    name,
    style: styles.default,
  });

  const selectProps = () => ({
    onChange: (e) => change(name, e.target.value),
    value: form.getByName(name),
  });

  const helperText = () => {
    return inputFeedback.errors.getByName(name);
  };

  const makeUncategorizedOptions = (options) => {
    return options
      .sort((a, b) => a.orderNo - b.orderNo)
      .map((option) => (
        <option key={option.code} value={option.code}>
          {option.label}
        </option>
      ));
  };

  const makeCategorizedOptions = (options) => {
    return new CategorizedOptions(options).getCategories().map((category) => (
      <optgroup key={category.code} label={category.label}>
        {makeUncategorizedOptions(category.options)}
      </optgroup>
    ));
  };

  const renderOptions = () => {
    const optionsNode = categorize
      ? makeCategorizedOptions(selectOptions)
      : makeUncategorizedOptions(selectOptions);

    if (blank) {
      optionsNode.unshift(
        <option key={UNSPECIFIED.code} value={UNSPECIFIED.code}>
          {labelText ? "" : blankLabel ? blankLabel : UNSPECIFIED.label}
        </option>
      );
    }
    return optionsNode;
  };

  const currentHelperText = helperText();

  return (
    <FormControl {...formControlProps()}>
      {labelText ? <InputLabel>{labelText}</InputLabel> : null}
      <NativeSelect {...selectProps()}>{renderOptions()}</NativeSelect>
      {currentHelperText ? (
        <FormHelperText>{currentHelperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

const styles = {
  default: {
    width: "100%",
  },
};

StandardSelect.propTypes = {
  blank: PropTypes.bool,
  blankLabel: PropTypes.string,
  categorize: PropTypes.bool,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
  inputFeedback: PropTypes.object.isRequired,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardSelect);

function mapStateToProps(state) {
  return {
    inputFeedback: state.inputFeedback,
  };
}

function mapDispatchToProps() {
  return {};
}
