import React, { useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";

const ScrollTopButton = () => {
  const [imageUrl, setImageUrl] = useState("/images/button_shadow.png");
  const [currentPosition, setCurrentPosition] = useState(0);

  const scrollTop = () => {
    return Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
  };

  const watchCurrentPosition = () => {
    const value = scrollTop();
    setCurrentPosition(value);
  };

  useEffect(() => {
    const scrollHandler = () => watchCurrentPosition();
    window.addEventListener("scroll", scrollHandler, false);
    return () => {
      window.removeEventListener("scroll", scrollHandler, false);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const position_number = currentPosition > 50;
  return (
    <div
      className="scroll scroll-up"
      onMouseEnter={() => setImageUrl("/images/button_noshadow.png")}
      onMouseLeave={() => setImageUrl("/images/button_shadow.png")}
    >
      <div className="arrowbounce" style={styles(position_number).arrowbounce}>
        <img src={imageUrl} onClick={scrollToTop} />
      </div>
    </div>
  );
};

const styles = (position_number) => ({
  arrowbounce: {
    opacity: `${position_number ? 1 : 0}`,
    position: "fixed",
    right: "2%",
    zIndex: 1,
    bottom: "5%",
  },
});

export default ScrollTopButton;
