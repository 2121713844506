import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../state/hojin-form/actions";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import { withInputFeedback } from "../../../../app/ui/form";
import HorizontalLabelLayout from "../../../../app/ui/form/HorizontalLabelLayout";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import InputTypeText from "../../../../app/ui/form/InputTypeText";
import Textarea from "../../../../app/ui/form/Textarea";
import InputTypeDate from "../../../../app/ui/form/InputTypeDate";
import { Card } from "@material-ui/core";
import Boundary from "../../../../app/ui/layout/Boundary";
import Right from "../../../../app/ui/layout/Right";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import ShortInputWidth from "../../../../app/ui/form/ShortInputWidth";

class HojinEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.reloadListPage = this.reloadListPage.bind(this);
  }

  initializeFields(props) {
    const { params } = props.match;
    this.id = params.id;
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.requestEditForm(this.id);
  }

  handleBackButtonClick() {
    const { actions, hojinList } = this.props;
    actions.gotoListPage(hojinList.search);
  }

  handleSaveButtonClick() {
    const { actions, hojinForm } = this.props;
    actions.submitToEdit(this.id, hojinForm.form, this.reloadListPage);
  }

  reloadListPage() {
    const { actions, hojinList } = this.props;
    actions.reloadListPage(hojinList.search);
  }

  createBindingProps() {
    const { actions, hojinForm } = this.props;
    const { form } = hojinForm;
    const { changeForm: onChange } = actions;
    return { form, onChange };
  }

  render() {
    return (
      <Boundary>
        <SideMargin>
          {this.renderHead()}
          {this.renderNavigation()}
          {this.renderForm()}
        </SideMargin>
      </Boundary>
    );
  }

  renderHead() {
    return <BackOfficeHead>法人編集</BackOfficeHead>;
  }

  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }

  renderForm() {
    const { hojinForm } = this.props;
    if (hojinForm.formIsLoading) return <CenteredCircularProgress />;
    if (!hojinForm.form.typeIs("edit")) return null;
    const bindingProps = this.createBindingProps();
    return (
      <Card>
        <SideMargin top bottom>
          <FormGrouping title="法人情報">
            <HorizontalLabelLayout labelText="法人名" required>
              <InputTypeText name="hojimmei" {...bindingProps} />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="☎ 電話番号">
              <ShortInputWidth>
                <InputTypeText
                  name="denwabango"
                  hintText="00-0000-0000"
                  {...bindingProps}
                />
              </ShortInputWidth>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="☎ FAX">
              <ShortInputWidth>
                <InputTypeText
                  name="fax"
                  hintText="00-0000-0000"
                  {...bindingProps}
                />
              </ShortInputWidth>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="郵便番号">
              <ShortInputWidth>
                <InputTypeText name="yubimbango" {...bindingProps} />
              </ShortInputWidth>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="本社所在地">
              <Textarea name="address" characterLimit={100} {...bindingProps} />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="担当者氏名">
              <ShortInputWidth>
                <InputTypeText name="tantoshashimei" {...bindingProps} />
              </ShortInputWidth>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="設立年月日">
              <ShortInputWidth>
                <InputTypeDate name="establishedDate" {...bindingProps} />
              </ShortInputWidth>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="事業内容">
              <Textarea
                name="setsumei"
                characterLimit={300}
                {...bindingProps}
              />
            </HorizontalLabelLayout>
          </FormGrouping>
          <Right>
            <SpaceOut>
              <FlatSecondaryButton onClick={this.handleBackButtonClick}>
                キャンセル
              </FlatSecondaryButton>
            </SpaceOut>
            <SpaceOut>
              <RaisedPrimaryButton
                disabled={hojinForm.formIsSubmitting}
                onClick={this.handleSaveButtonClick}
              >
                保存する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Right>
        </SideMargin>
      </Card>
    );
  }
}

HojinEditPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  match: PropTypes.object.isRequired,
  hojinForm: PropTypes.object.isRequired,
  hojinList: PropTypes.object.isRequired,
};

export default withInputFeedback(
  connect(mapStateToProps, mapDispatchToProps)(HojinEditPage)
);

function mapStateToProps(state) {
  return {
    hojinForm: state.hojinForm,
    hojinList: state.hojinList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoListPage: (search = "") =>
        dispatch(
          push({
            pathname: Pathname.backoffice_hojin,
            search,
          })
        ),
      reloadListPage: (search = "") => {
        const searchParams = new URLSearchParams(search);
        searchParams.set("timestamp", new Date().getTime());
        search = searchParams.toString();
        dispatch(
          push({
            pathname: Pathname.backoffice_hojin,
            search,
          })
        );
      },
    },
  };
}
