import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";

const InputTypeText = ({
  InputProps,
  children,
  disabled,
  form,
  helperText,
  hintText: placeholder,
  inputProps,
  labelText: label,
  multiline,
  name,
  onChange: change,
  onKeyDown,
  rows = 0,
  select,
  style,
  type = "text",
  autoComplete,
  inputFeedback,
}) => {
  const errorText = inputFeedback.errors.getByName(name);

  const textFieldProps = {
    InputProps,
    children,
    disabled,
    error: errorText ? true : false,
    helperText: errorText ? errorText : helperText,
    inputProps,
    label,
    margin: "normal",
    multiline,
    name,
    onChange: (e) => change(e.target.name, e.target.value),
    onKeyDown,
    placeholder,
    rows,
    rowsMax: rows * 3,
    select,
    style: {
      ...styles.default,
      ...style,
    },
    type,
    value: form.getByName(name),
    autoComplete,
  };

  return <TextField {...textFieldProps} />;
};

const styles = {
  default: {
    width: "100%",
  },
};

InputTypeText.propTypes = {
  InputProps: PropTypes.object,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
  helperText: PropTypes.string,
  hintText: PropTypes.string,
  inputFeedback: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  labelText: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  rows: PropTypes.number,
  select: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    inputFeedback: state.inputFeedback,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(InputTypeText);
